.new-collaborator-container {
    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin: 0 auto;
    padding: 20px;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.new-collaborator-container .content {
    width: 100%;
    padding: 50px;
    background: #25626e;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-collaborator-container .content section {
    width: 100%;
    max-width: 380px;
    margin-bottom: 20px;
}

.new-collaborator-container section.form {
    width: 100%;
    max-width: 350px;
    margin-right: 0;
    text-align: center;
}

.new-collaborator-container section.form img {
    width: 45%;
    margin: 0 auto;
    display: block;
}

.new-collaborator-container .content section h1 {
    margin: 32px 0 16px;
    font-size: 2rem;
    color: #db7335;
}

.new-collaborator-container .content section p {
    font-size: 1.125rem;
    color: #db7335;
    line-height: 1.5;
    text-align: center;
}

.new-collaborator-container .content form {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-collaborator-container .content form input {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.new-collaborator-container .content form .button {
    width: 100%;
    padding: 12px;
    background-color: #db7335;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 16px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.new-collaborator-container .content form .button:hover {
    background-color: #c1622e;
}

.new-collaborator-container .content form label{
    color: #db7335;
}

@media (max-width: 768px) {
    .new-collaborator-container {
        padding: 10px;
    }

    .new-collaborator-container .content {
        padding: 20px;
    }

    .new-collaborator-container .content section h1 {
        font-size: 1.75rem;
    }

    .new-collaborator-container .content section p {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .new-collaborator-container {
        flex-direction: column;
        height: auto;
    }

    .new-collaborator-container .content {
        padding: 15px;
    }

    .new-collaborator-container section.form img {
        width: 70%; /* Aumenta o tamanho da imagem em telas pequenas */
    }
}
