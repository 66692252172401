.login-container {
  width: 100%;
  max-width: 1120px;
  height: 100vh;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-container form h1 {
  font-size: 32px;
  margin-bottom: 32px;
  color: #fff;
}
