.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: #144c54;
    text-align: center;
  }
  
  .home-container header {
    margin-bottom: 50px;
  }
  
  .home-container h1 {
    font-size: 32px;
    color: #fff;
  }
  
  .home-container p {
    font-size: 20px;
    color: #e37636;
  }
  
  .options-container {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
  }
  
  .button {
    width: 250px;
    height: 60px;
    background: #e37636;
    border: 0;
    border-radius: 8px;
    color: #fff;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    line-height: 1.5;
    transition: filter 0.2s;
    text-decoration: none;
    cursor: pointer;
  }
  
  .button:hover {
    filter: brightness(80%);
  }
  
  @media (max-width: 768px) {
    .button {
      height: 50px;
      font-size: 16px;
    }
  }
  