.collaborator-container {
  padding: 20px 50px;
}

.collaborator-container header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collaborator-header {
  margin-top: 20px;
  padding: 20px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.collaborator-header h1 {
  margin: 0;
  font-size: 28px;
  color: #fff;
}

.collaborator-container header span {
  font-size: 20px;
  margin-left: 24px;
  color: #fff;
}

.collaborator-container header span strong {
  color: #e37636;
}

.collaborator-container header img {
  height: 64px;
}

.collaborator-container header a {
  width: 260px;
  margin-top: 0;
}

.header-buttons-container{
  display: flex;
  max-width: 19%;
  gap: 10px;
  justify-content: end;
}

.collaborator-container header button {
  height: 60px;
  width: 60px;
  border-radius: 4px;
  border: 1px solid #dcdce6;
  background: #fff;
  margin-left: 16px;
  transition: border-color 0.5s;
}

.collaborator-container header button:hover {
  border-color: #23626e;
}

.collaborator-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.collaborator-table th,
.collaborator-table td {
  padding: 15px;
  text-align: center;
  color: #41414d;
  border-bottom: 1px solid #ddd;
}

.collaborator-table th {
  background-color: #e37636;
  color: white;
  font-weight: bold;
}

.collaborator-table tr:hover {
  background-color: #f9f9f9;
}

.expiring {
  background-color: yellow;
}

.collaborator-table tr:last-child td {
  border-bottom: none;
}

.dropdown-button {
  background: none;
  border: none;
  color: #e37636;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-button:hover {
  color: #23626e;
}

.exam-list {
  margin-top: 10px;
  list-style: none;
  padding-left: 0;
  font-size: 14px;
}

.exam-list li {
  margin-bottom: 5px;
}

.search-input {
  padding: 10px 15px;
  border-radius: 20px;
  border: 1px solid #dcdce6;
  font-size: 16px;
  color: #41414d;
  background-color: #fff;
  width: 300px;
  outline: none;
  transition: border-color 0.5s;
}

.search-input:focus {
  border-color: #e37636;
}

.search-input::placeholder {
  color: #737380;
}

.exam-subtable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.exam-subtable th,
.exam-subtable td {
  padding: 10px;
  text-align: left;
  color: #41414d;
  border-bottom: 1px solid #ddd;
}

.exam-subtable th {
  background-color: #e37636;
  color: white;
  font-weight: bold;
}

.exam-subtable td {
  background-color: #f9f9f9;
}

.filter-select {
  background-color: #fff;
  color: #000;
  border-radius: 20px;
  border-color: transparent;
  height: 4vh;
  width: 10vw;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 60px;
  transition: filter 0.2s;
  cursor: pointer;
}

.filter-select:hover {
  filter: brightness(80%);
}

@media screen and (max-width: 768px) {
  .collaborator-container {
    padding: 10px;
  }

  .collaborator-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-input {
    width: 100%;
    margin-bottom: 10px;
  }

  .filter-select {
    width: 100%;
  }

  .collaborator-table {
    font-size: 12px;
  }
}