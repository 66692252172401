@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

* {
  margin: 0 px;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px Roboto, sans-serif;
  background: #144c54;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
}

input,
button,
textarea {
  font: 400 18px Roboto, sans-serif;
}

button {
  cursor: pointer;
}

form input {
  width: 100%;
  height: 60px;
  color: #33333333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 0 24px;
  margin-top: 16px;
}

.button {
  max-width: 50%;
  height: 60px;
  background: #e37636;
  border: 0;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
  transition: filter 0.2s;
  overflow: hidden;
  text-decoration: none;
  margin-top: 15px;
}

.button:hover {
  filter: brightness(80%);
}

@media (max-width: 768px) {
  .button {
    height: 50px;
    font-size: 16px;
  }
}

.button-home {
  max-width: 25%;
  height: 60px;
  background: #FFF;
  border: 0;
  border-radius: 8px;
  color: #e37636;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
  transition: filter 0.2s;
  overflow: hidden;
  text-decoration: none;
}

.button-home:hover {
  filter: brightness(80%);
}

@media (max-width: 768px) {
  .button-home {
    height: 50px;
    font-size: 16px;
  }
}